import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import Card from '../../components/card'

const HeroImage = ({ file, alt }) => {
  const image = getImage(file)

  return(
    <GatsbyImage className="h-48 rounded-t-md md:h-72 lg:h-96" image={image} alt={alt || ""}/>
  )
}

const BlogPost = ({ data }) => {
  const frontmatter = data.mdx.frontmatter
  const heroImage = frontmatter.heroImage
  
  return (
    <Layout pageTitle={frontmatter.title}>
      <Card>
        <div className="p-2 space-y-3">
          <div>
            {heroImage && <HeroImage {...heroImage}/>}
          </div>
          <div>
            <p className="text-xl text-teal-600 dark:text-teal-400">{frontmatter.title}</p>
            <p className="text-xs text-zinc-400">{frontmatter.date}</p>
          </div>
          <div className="mdx space-y-3">
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
          </div>
        </div>
      </Card>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        heroImage {
          alt
          credit {
            link
            text
          }
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      body
    }
  }
`

export default BlogPost
